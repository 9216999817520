.scroll-container {
  width: 107px;
  overflow: hidden;
}

.scroll-text {
  white-space: nowrap;
  /*transition: all 1s ease;*/
  /* animation properties */
  /*-moz-transform: translateX(100%);*/
  /*-webkit-transform: translateX(100%);*/
  /*transform: translateX(100%);*/

  /*animation: small-animation 2s linear infinite;*/
}

.scroll-container-fullwidth {
  width: 100%;
  overflow: hidden;
}

.scroll-text-fullwidth {
  white-space: nowrap;
  /*transition: all 3s ease;*/

  animation: big-animation 100s linear infinite;
}

.scroll-text-fullwidth.direction-right {
  animation: big-animation-right 100s linear infinite;
}

@keyframes small-animation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-60%);
  }
}

@keyframes big-animation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes big-animation-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
