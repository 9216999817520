@media screen and (min-width: 1024px) {
  .hero {
    position: relative;
    overflow: hidden;
    background-image:
            url("../../static/images/lambda.png"),
            url("../../static/images/gradient.png");
    background-position: center;
    background-size: cover, contain;
    background-position-y: 57.21px;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1024px) {
  .core-features {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    position: relative;
    overflow: hidden;
    background-image:
            url("../../static/images/lambda.png"),
            url("../../static/images/gradient.png");
    background-position: center;
    background-size: cover, contain;
    background-position-y: 57.21px;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 640px) {
  .hero {
    position: relative;
    overflow: hidden;
    background-image:
            url("../../static/images/lambda.png"),
            url("../../static/images/gradient.png");
    background-repeat: no-repeat;
    background-size: cover, contain;
    background-position: center;
    background-position-y: -20px;
  }
}
