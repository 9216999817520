.dots {
  position: absolute;
  top: 350px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.rainbow-feature-title {
  height: 116px;
  width: 440px;
  border-radius: 4px;
}

.rainbow-feature-title:hover {
  height: 116px;
  width: 440px;
  background-color: rgb(255, 255, 255, 0.06);
}
