@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'FK Grotesk Neue Trial';
  src: url('./static/fonts/fkgroteskneuetrial-thin-webfont.woff2') format('woff2'),
  url('./static/fonts/fkgroteskneuetrial-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'FK Grotesk Neue Trial';
  src: url('./static/fonts/fkgroteskneuetrial-light-webfont.woff2') format('woff2'),
  url('./static/fonts/fkgroteskneuetrial-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'FK Grotesk Neue Trial';
  src: url('./static/fonts/fkgroteskneuetrial-regular-webfont.woff2') format('woff2'),
  url('./static/fonts/fkgroteskneuetrial-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'FK Grotesk Neue Trial';
  src: url('./static/fonts/fkgroteskneuetrial-medium-webfont.woff2') format('woff2'),
  url('./static/fonts/fkgroteskneuetrial-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'FK Grotesk Neue Trial';
  src: url('./static/fonts/fkgroteskneuetrial-bold-webfont.woff2') format('woff2'),
  url('./static/fonts/fkgroteskneuetrial-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}





body {
  margin: 0;
  font-family: 'FK Grotesk Neue Trial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #101010;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-container {
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.section-container::-webkit-scrollbar {
  display: none;
}

.section {
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
}

.long-section {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.long-section::-webkit-scrollbar {
  display: none;
}

.advantages-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 20px;
}

[class*="Snackbar_snackbar-wrapper__"] {
  z-index: 500;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

@media screen and (min-width: 1024px) {
  .advantages-section {
    margin: 0 auto;
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .footer-background {
    top: 50px;
    bottom: -280px;
    background-image: url("./static/images/lambda.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    height: auto;
    scroll-snap-align: none;
  }

  .long-section {
    overflow-y: auto;
  }

  .long-section::-webkit-scrollbar {
    display: inherit;
  }

  .footer-background {
    top: 20px;
    bottom: -280px;
    background-image: url("./static/images/lambda.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 640px) {
  .footer-background {
    top: 290px;
    bottom: -140px;
    background-image: url("./static/images/lambda.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.section-container {
  margin: auto;
}