.wrapper.focused {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.placeholder {
  position: absolute;
  top: calc(50% - 12px);
  left: 1.25rem;
  color: #ffffff80;
}

.placeholder.focused,
.placeholder.valued {
  pointer-events: none;
  transform: translate(0, -15px) scale(0.6);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.placeholder.focused {
  color: #fff;
}

.input:focus {
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.fakeOption input{
  display: none;
}

.advantages-square {
  content: '';
  border: 1px solid #FFFFFF4D;
  width: 23px;
  height: 23px;
  background-color: black;
  border-radius: 4px;
}

.advantages-square-checked {
  content: '';
  border: 1px solid #FFFFFF4D;
  width: 23px;
  height: 23px;
  background-image: url("../../static/images/icons/check.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 4px;
}
